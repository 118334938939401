import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Button,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";
import Switch from "@material-ui/core/Switch";

import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import {
	ButtonInfo,
	ButtonPrimary,
	ButtonSecondary,
	ButtonWarning,
	ButtonDanger,
} from "../../UI/Buttons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";

import { deleteBlockAttachment } from "../../../apis/system/violations/index";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";

class BlockAttachmentsList extends Component {
	state = {
		filesList: [],
	};

	handleDelete = async ({ data }) => {
		let at = this.props.currentUser.Data.access_token,
			blockId = data.Client_Blocking_Id,
			fileCode = data.File_Code;

		this.props.setLoading(true, "dark");

		await deleteBlockAttachment(at, blockId, fileCode)
			.then((res) => {
				if (res.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res);
					this.setState({ filesList: res.Data });
				} else {
					throw res;
				}

				return res;
			})
			.catch((error) => {
				this.props.setLoading(false);

				this.props.triggerNotification(
					true,
					error.response ? error.response.data : error.data
				);

				return error.response;
			});
	};

	componentDidMount() {
		if (this.props.filesList) {
			this.setState({ filesList: this.props.filesList });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.filesList !== this.props.filesList) {
			this.setState({ filesList: this.props.filesList });
		}
	}

	render() {
		return (
			<>
				<div className="container-fluid pb-3 mt-5">
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						key="Violation_Attachment_Id"
						dataSource={this.state.filesList}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={false}
						columnWidth={"auto"}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
					>
						<ColumnChooser enabled={false} allowSearch={false} />

						<SearchPanel
							visible={false}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Column
							dataField="File_Name"
							caption={this.props.t("File_Name")}
							alignment={
								this.props.i18n.language === "ar"
									? "right"
									: "left"
							}
						></Column>

						<Column
							dataField="File_Type"
							caption={this.props.t("File_Type")}
							alignment={
								this.props.i18n.language === "ar"
									? "right"
									: "left"
							}
						></Column>

						<Column
							dataField="File_Size"
							caption={this.props.t("File_Size")}
							alignment={
								this.props.i18n.language === "ar"
									? "right"
									: "left"
							}
						></Column>

						<Column
							cssClass="outlinedColumn nowrapColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
							width="250px"
						>
							<Button
								render={(record) => {
									return (
										<div>
											<GetAppIcon
												style={{
													cursor: "pointer",
													marginInline: "8px",
												}}
												onClick={async () => {
													let params = {
															at: this.props
																.currentUser
																.Data
																.access_token,
															File_Code:
																record.data
																	.File_Code,
														},
														File_Name =
															record.data
																.File_Name;

													const downloadResult =
														await downloadAttachment(
															params
														);
													const url =
														window.URL.createObjectURL(
															new Blob(
																[
																	downloadResult.data,
																],
																{
																	type: downloadResult
																		.headers[
																		"content-type"
																	],
																}
															)
														);
													const link =
														document.createElement(
															"a"
														);
													link.href = url;
													link.setAttribute(
														"download",
														File_Name
													);
													link.click();
												}}
											/>

											<ButtonWarning
												style={{
													marginInline: "8px",
												}}
												variant="circleIcon"
												startIcon={<VisibilityIcon />}
												onClick={async () => {
													let params = {
														at: this.props
															.currentUser.Data
															.access_token,
														File_Code:
															record.data
																.File_Code,
													};
													const downloadResult =
														await downloadAttachment(
															params
														);

													if (
														downloadResult.status ===
														200
													) {
														let contentType =
																downloadResult
																	.headers[
																	"content-type"
																],
															typesArray = [
																"application/pdf",
																"image/bmp",
																"image/gif",
																"image/vnd.microsoft.icon",
																"image/jpeg",
																"image/png",
																"image/svg+xml",
																"image/tiff",
																"image/webp",
															],
															url =
																window.URL.createObjectURL(
																	new Blob(
																		[
																			downloadResult.data,
																		],
																		{
																			type: contentType,
																		}
																	)
																),
															link =
																document.createElement(
																	"a"
																);
														let File_Name;
														if (
															downloadResult?.data
																?.Data
																?.File_Bytes
														) {
															url =
																window.URL.createObjectURL(
																	new Blob(
																		[
																			downloadResult
																				.data
																				.Data
																				.File_Bytes,
																		],
																		{
																			type: contentType,
																		}
																	)
																);
															File_Name =
																downloadResult
																	.data.Data
																	.File_Name;
														}
														link.href = url;
														typesArray.includes(
															contentType
														)
															? link.setAttribute(
																	"target",
																	"_blank"
															  )
															: link.setAttribute(
																	"download",
																	File_Name
															  );
														document.body.appendChild(
															link
														);
														link.click();
													}
												}}
											>
												{/* {this.props.t('view')} */}
											</ButtonWarning>

											{this.props.allowDelete && (
												<ButtonDanger
													style={{
														marginInline: "8px",
													}}
													variant="circleIcon"
													startIcon={<DeleteIcon />}
													onClick={() => {
														this.handleDelete(
															record
														);
													}}
												>
													{/* {this.props.t('view')} */}
												</ButtonDanger>
											)}
										</div>
									);
								}}
							/>
						</Column>
					</DataGrid>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	triggerNotification,
	setLoading,
})(translationHook(BlockAttachmentsList));
