import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary, ButtonSecondary, ButtonDanger } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Autocomplete, Select } from "mui-rff";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { getBlockDetails } from "../../../apis/system/violations/index";

import BlockAttachmentsList from "./BlockAttachmentsList";

class BlockDetailForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initValues: null,
			dataLoaded: false,
			Attached_Files: [],
			clientsList: [],
			Enable_Edit_Blocking: 0,
			Blocking_Attachments: [],
			lockFields: true,
		};
	}

	selectFiles = (event, field) => {
		let filesData = this.state.Attached_Files;

		for (const file in event.target.files) {
			if (Object.hasOwnProperty.call(event.target.files, file)) {
				const element = event.target.files[file];
				element["Can_Delete"] = 1;
				element[
					"Key"
				] = `attach_${file}_${element.lastModified}_${element.size}`;
				filesData.push(element);
			}
		}

		this.setState({
			Attached_Files: filesData,
		});
	};

	getClientsList = async () => {
		let at = this.props.currentUser.Data.access_token,
			arr = [];

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Get_Clients_Profiles_Without_Blocking_List",
			{},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Client_Name_AR
						: item.Client_Name_EN;

				arr.push({
					value: item.Client_Profile_Id,
					label,
				});
			});

			this.setState({ clientsList: arr });
		}
	};

	getClientDetails = async ({ value }) => {
		let at = this.props.currentUser.Data.access_token;

		if (this.props.blockId) {
			return;
		}

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Get_Blocked_Client_Profile_Info",
			{ Client_Profile_Id: value },
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({
				initValues: {
					Dealing_Type_Name_AR:
						response.data.Data[0].Dealing_Type_Name_AR,
					Dealing_Type_Name_EN:
						response.data.Data[0].Dealing_Type_Name_EN,
					Client_License_No: response.data.Data[0].Client_License_No,
					Client_License_Expired_Date:
						response.data.Data[0].Client_License_Expired_Date,
					Client_Blocking_Date: moment().format("YYYY-MM-DD"),
					Client_Profile_Id: value,
				},
			});
		}
	};

	cancelViolation = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Cancel_Blocking";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{ Client_Blocking_Id: this.props.blockId },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);

					let vDetails = res?.data?.Data?.Blocking_Data;
					vDetails["Client_Blocking_Date"] = moment(
						vDetails.Client_Blocking_Date
					).format("YYYY-MM-DD");

					this.setState({
						initValues: vDetails,
						Blocking_Attachments:
							res?.data?.Data?.Blocking_Attachments,
						Enable_Edit_Blocking:
							res?.data?.Data?.Enable_Edit_Blocking,
					});
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);
				this.props.triggerNotification(
					true,
					err.response ? err.response.data : err.data
				);

				return err;
			});
	};

	closeViolation = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Close_Blocking";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{ Client_Blocking_Id: this.props.blockId },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);

					let vDetails = res?.data?.Data?.Blocking_Data;
					vDetails["Client_Blocking_Date"] = moment(
						vDetails.Client_Blocking_Date
					).format("YYYY-MM-DD");

					this.setState({
						initValues: vDetails,
						Blocking_Attachments:
							res?.data?.Data?.Blocking_Attachments,
						Enable_Edit_Blocking:
							res?.data?.Data?.Enable_Edit_Blocking,
					});
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);
				this.props.triggerNotification(
					true,
					err.response ? err.response.data : err.data
				);

				return err;
			});
	};

	handleUploadAttachments = async () => {
		let at = this.props.currentUser.Data.access_token,
			bodyFormData = new FormData(),
			url = "/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Add_Blocking_Files";

		bodyFormData.append(
			"PARAMETERS",
			JSON.stringify({ Client_Blocking_Id: this.props.blockId })
		);

		this.state.Attached_Files.forEach((file) => {
			bodyFormData.append("Attached_Files[]", file);
		});

		const res = await apiConfig.post(url, bodyFormData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});
		return res;
	};

	handleUpdateDetails = async (values) => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Upd_Blocking",
			bodyData = {
				Client_Blocking_Id: this.props.blockId,
				Client_Blocking_Notes: values.Client_Blocking_Notes,
			};

		const res = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		return res;
	};

	onSectionSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			bodyFormData = new FormData(),
			url =
				!this.props.blockId && !this.props.isStepForm
					? "/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Add_Blocking"
					: this.props.isStepForm
					? "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Task_Inspection_Violation"
					: "/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Upd_Blocking",
			bodyData = {
				Client_Profile_Id: values.Client_Profile_Id,
				Client_Blocking_Notes: values.Client_Blocking_Notes,
			};

		this.props.setLoading(true, "dark");

		// update
		if (this.props.blockId) {
			Promise.all([
				this.handleUpdateDetails(values),
				this.handleUploadAttachments(),
			])
				.then((res) => {
					//  details res
					if (res[0].data.Status) {
						let vDetails = res[0]?.data?.Data?.Blocking_Data;
						vDetails["Client_Blocking_Date"] = moment(
							vDetails.Client_Blocking_Date
						).format("YYYY-MM-DD");

						this.props.setLoading(false);
						this.props.triggerNotification(true, res[0].data);

						this.setState({
							initValues: vDetails,
							Blocking_Attachments:
								res[0]?.data?.Data?.Blocking_Attachments,
							Enable_Edit_Blocking:
								res[0]?.data?.Data?.Enable_Edit_Blocking,
							dataLoaded: true,
							Attached_Files: [],
						});
					} else {
						throw res[0];
					}

					// attachment res
					if (res[1].data.Status) {
						this.setState({
							Blocking_Attachments: res[1]?.data?.Data,
						});
					} else {
						throw res[1];
					}

					return res;
				})
				.catch((error) => {
					this.props.setLoading(false);

					this.props.triggerNotification(
						true,
						error.response ? error.response.data : error.data
					);

					return error.response;
				});
		}

		// add
		if (!this.props.blockId) {
			// steps form
			if (this.props.isStepForm) {
				bodyData["Request_Task_Id"] = this.props.requestTaskId;
				bodyData["Request_Id"] = this.props.requestId;
			}

			bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

			this.state.Attached_Files.forEach((file) => {
				bodyFormData.append("Attached_Files[]", file);
			});

			await apiConfig
				.post(url, bodyFormData, {
					headers: {
						Authorization: `Bearer ${at}`,
					},
				})
				.then((res) => {
					if (res.data.Status) {
						this.props.setLoading(false);
						this.props.triggerNotification(true, res.data);

						if (!this.props.isStepForm) {
							this.props.triggerDialogForm(false);
							navigate(
								`/manage-blocks/block-info/${res.data.Data.Data.Blocking_Data.Client_Blocking_Id}`
							);
						} else {
							this.props.submitCB(res);
							form.restart();
						}
					} else {
						throw res;
					}

					return res;
				})
				.catch((error) => {
					this.props.setLoading(false);

					this.props.triggerNotification(
						true,
						error.response ? error.response.data : error.data
					);

					return error.response;
				});
		}
	};

	componentDidMount() {
		let auth = this.props.currentUser.Data.access_token;

		if (this.props.isRequestForm) {
			this.setState({
				initValues: this.props.blockingInfo,
				Blocking_Attachments: this.props.blockingAttachments,
				dataLoaded: true,
			});

			return;
		}

		Promise.all([this.getClientsList()]).then(() => {
			if (!this.props.blockId) {
				this.setState({
					dataLoaded: true,
					lockFields: false,
					Enable_Edit_Blocking: 1,
				});
			}
		});

		if (this.props.isStepForm) {
			this.setState({ initValues: this.props.violationInfo });
		}

		if (this.props.blockId) {
			(async () => {
				const details = await getBlockDetails(
					auth,
					this.props.blockId
				).then((res) => {
					if (res?.data?.Status) {
						let vDetails = res?.data?.Data?.Blocking_Data;
						vDetails["Client_Blocking_Date"] = moment(
							vDetails.Client_Blocking_Date
						).format("YYYY-MM-DD");

						this.setState({
							initValues: vDetails,
							Blocking_Attachments:
								res?.data?.Data?.Blocking_Attachments,
							Enable_Edit_Blocking:
								res?.data?.Data?.Enable_Edit_Blocking,
							dataLoaded: true,
						});
					}
				});
			})();
		}
	}

	render() {
		let { initValues } = this.state;

		const validate = (values) => {
			const errors = {};

			return errors;
		};

		if (!this.state.dataLoaded) {
			return (
				<p
					style={{
						textAlign: "start",
						padding: "10px",
					}}
				>
					{this.props.t("loadingClientData")}
				</p>
			);
		} else {
			return (
				<>
					{this.props.blockId && (
						<div className="d-flex justify-content-between align-items-center pb-5">
							<h3>{this.props.t("manageBlock")}</h3>

							<ButtonSecondary
								variant="outlined"
								endIcon={<ExitToAppIcon />}
								component={Link}
								to="/manage-blocks"
								size="large"
							>
								{this.props.t("exitToBlockinglist")}
							</ButtonSecondary>
						</div>
					)}

					<Form
						onSubmit={this.onSectionSubmit}
						initialValues={
							initValues
								? initValues
								: {
										Client_Blocking_Id: "",
										Client_Blocking_Notes: "",
										Client_Blocking_Date:
											moment().format("YYYY-MM-DD"),
								  }
						}
						validate={validate}
						render={({
							handleSubmit,
							form,
							submitting,
							pristine,
							values,
						}) => (
							<form
								id="sliderform"
								className={`row pt-3`}
								onSubmit={handleSubmit}
								noValidate
							>
								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("blockingID")}
										className="mb-2"
										name="Client_Blocking_Id"
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("blockingNo")}
										className="mb-2"
										name="Client_Blocking_No"
									/>
								</Col>

								{!this.props.isRequestForm && (
									<Col xs={12} md={4}>
										<TextField
											disabled
											label={this.props.t(
												"blockingStatus"
											)}
											className="mb-2"
											name={
												this.props.i18n.language ===
												"ar"
													? "Clients_Blocking_Status_Name_AR"
													: "Clients_Blocking_Status_Name_EN"
											}
										/>
									</Col>
								)}

								<Col xs={12} md={8}>
									{!this.props.blockId &&
									!this.props.isRequestForm ? (
										<Field name="Client_Profile_Id">
											{(fieldProps) => (
												<Autocomplete
													disabled={
														this.props.blockId ||
														this.props.isStepForm
													}
													label={this.props.t(
														"client"
													)}
													name="Client_Profile_Id"
													options={
														this.state.clientsList
													}
													getOptionValue={(option) =>
														option.value
													}
													getOptionLabel={(option) =>
														option.label
													}
													showError={({
														meta: {
															submitFailed,
															invalid,
														},
													}) => {
														return (
															submitFailed &&
															invalid
														);
													}}
													onChange={(e, v) => {
														this.getClientDetails(
															v
														);
													}}
												></Autocomplete>
											)}
										</Field>
									) : (
										<TextField
											disabled
											label={this.props.t("client")}
											className="mb-2"
											name={
												this.props.i18n.language ===
												"ar"
													? "Client_Name_AR"
													: "Client_Name_EN"
											}
										/>
									)}
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("blockingDate")}
										className="mb-2"
										name="Client_Blocking_Date"
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("clientType")}
										className="mb-2"
										name={
											this.props.i18n.language === "ar"
												? "Dealing_Type_Name_AR"
												: "Dealing_Type_Name_EN"
										}
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("licenseNo")}
										className="mb-2"
										name="Client_License_No"
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t(
											"licenseExpireDate"
										)}
										className="mb-2"
										name="Client_License_Expired_Date"
									/>
								</Col>

								<Col xs={12}>
									<TextField
										disabled={
											this.state.lockFields ||
											!this.state.Enable_Edit_Blocking ||
											this.props.isRequestForm
										}
										label={this.props.t("blockingNotes")}
										className="mb-2"
										name="Client_Blocking_Notes"
										multiline
										rows={5}
									/>
								</Col>

								<Col xs={12}>
									<Row className="mt-5 mx-1">
										<Col
											md={6}
											className="d-flex flex-column align-items-start"
										>
											<div
												style={{
													flexGrow: 1,
												}}
											>
												<label
													style={{
														fontSize: "1rem",
														color: "#669900",
														textAlign: "right",
														display: "block",
														right: "0.5rem",
														position: "relative",
														marginBottom: "5px",
														marginTop: "-4px",
													}}
												>
													{this.props.t(
														"blockingttachments"
													)}
												</label>

												{this.state.Attached_Files &&
												this.state.Attached_Files
													.length > 0 &&
												!this.props.isRequestForm ? (
													<FilesUpload
														view={true}
														attachments={[
															{
																Files_Data:
																	this.state
																		.Attached_Files,
															},
														]}
														deleteCB={(params) => {
															let filterArr =
																this.state.Attached_Files.filter(
																	(f) =>
																		f.Key !==
																		params.Key
																);

															this.setState({
																Attached_Files:
																	filterArr,
															});
														}}
													/>
												) : null}
											</div>
											{!this.state.lockFields &&
												!!this.state
													.Enable_Edit_Blocking &&
												!this.props.isRequestForm && (
													<ButtonSecondary
														color="primary"
														variant="contained"
														endIcon={
															<CloudUploadIcon />
														}
														style={{
															justifyContent:
																"space-between",
															minWidth: "200px",
															marginTop: "18px",
															marginBottom:
																"18px",
														}}
													>
														{this.props.t(
															"addAttachments"
														)}
														<input
															style={{
																position:
																	"absolute",
																width: "100%",
																height: "100%",
																left: 0,
																right: 0,
																top: 0,
																bottom: 0,
																zIndex: 1,
																opacity: 0,
																fontSize: 0,
																cursor: "pointer",
															}}
															type="file"
															onChange={(e) => {
																this.selectFiles(
																	e,
																	"Attached_Files"
																);
															}}
															value=""
															multiple
														/>
													</ButtonSecondary>
												)}
										</Col>
									</Row>

									{((this.props.blockId &&
										this.state.Blocking_Attachments
											?.length > 0) ||
										this.props.isRequestForm) && (
										<BlockAttachmentsList
											filesList={
												this.state.Blocking_Attachments
											}
											allowDelete={
												!this.state.lockFields &&
												!!this.state
													.Enable_Edit_Blocking &&
												!this.props.isRequestForm
											}
										/>
									)}
								</Col>

								<Col
									className="my-4 justify-content-start d-flex"
									xs={12}
								>
									{!this.props.blockId &&
										!this.props.isRequestForm && (
											<ButtonPrimary
												color="primary"
												variant="contained"
												disabled={
													submitting ||
													this.props.viewMood
												}
												type="submit"
											>
												{this.props.t("save")}
											</ButtonPrimary>
										)}

									{!this.state.lockFields &&
										!!this.state.Enable_Edit_Blocking &&
										this.props.blockId && (
											<ButtonPrimary
												color="primary"
												variant="contained"
												disabled={
													submitting ||
													this.props.viewMood
												}
												type="submit"
											>
												{this.props.t("save")}
											</ButtonPrimary>
										)}

									{this.state.lockFields &&
										!!this.state.Enable_Edit_Blocking &&
										this.props.blockId && (
											<ButtonPrimary
												color="primary"
												variant="contained"
												disabled={
													submitting ||
													this.props.viewMood
												}
												onClick={() => {
													this.setState({
														lockFields: false,
													});
												}}
											>
												{this.props.t("edit")}
											</ButtonPrimary>
										)}

									{!this.state.lockFields &&
										!!this.state.Enable_Edit_Blocking &&
										this.props.blockId && (
											<>
												<ButtonSecondary
													className="mx-2"
													color="primary"
													variant="contained"
													onClick={() => {
														this.closeViolation();
													}}
												>
													{this.props.t("closeBlock")}
												</ButtonSecondary>

												<ButtonDanger
													className="mx-2"
													color="primary"
													variant="contained"
													onClick={() => {
														this.cancelViolation();
													}}
												>
													{this.props.t(
														"cancelBlock"
													)}
												</ButtonDanger>
											</>
										)}
								</Col>
							</form>
						)}
					/>
				</>
			);
		}
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(BlockDetailForm));
