import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Pager,
	Paging,
	StateStoring,
} from "devextreme-react/data-grid";
import { Link } from "@reach/router";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import Switch from "@material-ui/core/Switch";

import apiConfig from "../../../apis/apiConfig";

import {
	getViolationCategories,
	deleteViolationCategory,
	setViolationCategoryStatus,
} from "../../../apis/system/violations/index";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import {
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
	ButtonPrimary,
} from "../../UI/Buttons";

class ViolationCategoriesList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
		this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
		// this.devRef = React.createRef(null);
		this.searchState = React.createRef();
	}

	state = {
		gridState: [],
	};

	componentDidMount() {
		this.loadState();
	}

	saveState = async () => {
		const at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/GENERAL/SAVE_USER_GRID_SETTINGS",
			bodyData = {
				System_Grid_Name: "Grid_Lookups_Violations_Categories_List",
				System_Grid_Json: this?.searchState?.current,
				Lang: this.props.i18n.language,
			};

		await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		this.props.triggerNotification(true, {
			Status: true,
		});
	};

	getState = async (state) => {
		if (state) {
			state.searchText = "";
			state.columns?.map((s) => (s.filterValue = undefined));
			this.searchState.current = JSON.stringify(state);
		}
	};

	loadState = async () => {
		const at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/GENERAL/GET_USER_GRID_SETTINGS",
			bodyData = {
				System_Grid_Name: "Grid_Lookups_Violations_Categories_List",
				Lang: this.props.i18n.language,
			};

		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		if (response.data) {
			this.setState({
				gridState: JSON.parse(response?.data?.Data),
				// showGrid: true,
				pageSize: JSON.parse(response?.data?.Data)?.pageSize,
			});
		}
	};

	onToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: "after",
				widget: "dxButton",
				options: {
					text: this.props.t("saveGridSettings"),
					height: "36px",
					icon: "save",
					onClick: () => this.saveState(),
					hint: this.props.t("saveGridSettings"),
				},
			},
			{
				location: "after",
				widget: "dxButton",
				options: {
					text: this.props.t("clearSearch"),
					height: "36px",
					icon: "clearformat",
					onClick: () => this.dataGrid?.instance?.clearFilter(),
					hint: this.props.t("clearSearch"),
				},
			}
		);
	}

	handleOnContentReady = (e) => {
		if (this.props.i18n.language === "ar") {
			const scrollable = e.component?.getScrollable();
			const maxScrollLeft = scrollable?.scrollWidth();

			scrollable?.scrollTo({
				x: maxScrollLeft,
			});
		}
	};

	customizeCellCheckbox = ({ data }) => {
		return (
			<Switch
				checked={data.Active_Status_Id}
				name={`centerStatus_${data.Violation_Category_Id}`}
				onChange={async () => {
					let at = this.props.currentUser.Data.access_token,
						dataSent = {
							Violation_Category_Id: data.Violation_Category_Id,
							Active_Status_Id: data.Active_Status_Id ? 0 : 1,
						};

					this.props.setLoading(true, "dark");

					const response = await setViolationCategoryStatus(
						at,
						dataSent
					);

					this.props.triggerNotification(true, response);

					this.props.setLoading(false);
				}}
			/>
		);
	};

	// renderEditForm = (...params) => {
	// 	let viewMood = params[2] === "view" ? true : false,
	// 		editMood = params[2] === "edit" ? true : false;

	// 	return (
	// 		<DecisionCategoryForm
	// 			decisionCatId={params[0]}
	// 			initValues={params[1]}
	// 			editMood={editMood}
	// 			viewMood={viewMood}
	// 		/>
	// 	);
	// };

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			violationCatId = data.Violation_Category_Id,
			decisionCatName =
				this.props.i18n.language === "ar"
					? data.Violation_Category_Name_AR
					: data.Violation_Category_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${decisionCatName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteViolationCategory,
				params: [at, violationCatId],
				text: this.props.t("delete"),
			},
		});
	};

	handleEdit = (data, viewMood) => {
		let decisionCatId = data.Decision_category_Id,
			decisionCatName =
				this.props.i18n.language === "ar"
					? data.Decision_category_Name_AR
					: data.Decision_category_Name_EN,
			dialogTitle = `${
				viewMood === "view"
					? this.props.t("view")
					: this.props.t("edit")
			} ${decisionCatName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderEditForm,
			params: [decisionCatId, data, viewMood],
		});
	};

	render() {
		let columnAlign = "left",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "Violation_Category_Id",
			load: () => {
				return getViolationCategories(at);
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h3>{this.props.t("violationCategoriesList")}</h3>

					<ButtonPrimary
						// variant="outlined"
						component={Link}
						to="/violation-categories/add-category"
						size="large"
					>
						{this.props.t("addCategory")}
					</ButtonPrimary>
				</div>

				{this.state.gridState?.columns && (
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						onExporting={this.onExporting}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
						onToolbarPreparing={this.onToolbarPreparing}
						ref={(ref) => (this.dataGrid = ref)}
						bounceEnabled={false}
						onOptionChanged={(e) => {
							if (e?.fullName == "paging.pageSize") {
								this.setState({ pageSize: e.value });
							}

							if (e.fullName.split(".").pop() == "filterValue") {
								this.dataGrid.instance.refresh();
							}
						}}
						onContentReady={this.handleOnContentReady}
						remoteOperations={false}
					>
						<ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/>

						<StateStoring
							enabled={true}
							type="custom"
							// customLoad={this.loadState}
							customSave={this.getState}
						/>

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true} />

						<Grouping
							contextMenuEnabled={true}
							expandMode="rowClick"
						/>
						<GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						></GroupPanel>

						<Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/>

						<Paging
							enabled={true}
							defaultPageSize={this.state.pageSize}
							pageSize={this.state.pageSize}
						/>

						<Pager
							visible="true"
							allowedPageSizes={[5, 10, 20, 50, 100]}
							displayMode="full"
							showPageSizeSelector="true"
							showInfo="true"
							infoText=""
							showNavigationButtons="true"
						/>

						{this.state.gridState?.columns
							?.filter(
								(d) =>
									d?.name !== "buttons" &&
									d?.name !== "Active_Status_Id"
							)
							?.map((d, i) => (
								<Column
									dataField={d?.dataField}
									caption={d?.name}
									alignment={
										this.props.i18n.language === "ar"
											? "right"
											: "left"
									}
									visible={d?.visible}
									width={d?.width ? d.width : "auto"}
									dataType={d?.dataType}
									format={
										d?.dataType == "date"
											? "yyyy-MM-dd"
											: ""
									}
								></Column>
							))}

						<Column
							dataField="Active_Status_Id"
							caption={this.props.t("serviceStatus")}
							allowFiltering={false}
							width={"120px"}
							alignment={"center"}
							cellRender={this.customizeCellCheckbox}
						></Column>

						<Column
							cssClass="outlinedColumn nowrapColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
						>
							<Button
								render={(record) => {
									return (
										<ButtonWarning
											variant="circleIcon"
											startIcon={<EditIcon />}
											component={Link}
											to={`/violation-categories/edit-category/${record?.data?.Violation_Category_Id}`}
										></ButtonWarning>
									);
								}}
							/>
							<Button
								render={(record) => {
									return (
										<ButtonDanger
											variant="circleIcon"
											startIcon={<DeleteIcon />}
											onClick={() =>
												this.handleDelete(record.data)
											}
										>
											{/* {this.props.t('delete')} */}
										</ButtonDanger>
									);
								}}
							/>
						</Column>
					</DataGrid>
				)}
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageCenters");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	triggerNotification,
	setLoading,
})(translationHook(ViolationCategoriesList));
