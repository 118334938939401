import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";
import Switch from "@material-ui/core/Switch";

import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";

import {
	deleteViolationHolder,
	addViolationHolder,
	getViolationTypeHoldersList,
} from "../../../apis/system/violations/index";

class ViolationTypeHoldersList extends Component {
	customizeCellCheckbox = ({ data }) => {
		return (
			<Switch
				checked={data.Selected}
				name={`holder_${data.Violation_Holder_Id}`}
				onChange={async () => {
					let at = this.props.currentUser.Data.access_token,
						status = data.Selected,
						violationTypeId = this.props.violationTypeId,
						holderId = data.Violation_Holder_Id,
						holderTypeId = data.Violation_Type_Holder_Id,
						response;

					this.props.setLoading(true, "dark");

					if (status) {
						response = await deleteViolationHolder(
							at,
							violationTypeId,
							holderTypeId
						);
					} else {
						response = await addViolationHolder(
							at,
							violationTypeId,
							holderId
						);
					}

					this.props.triggerNotification(true, response);

					this.props.setLoading(false);
				}}
			/>
		);
	};

	render() {
		let columnAlign = "left",
			SectionNameKey = "Violation_Holder_Name_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			SectionNameKey = "Violation_Holder_Name_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Violation_Holder_Id",
			load: async () => {
				if (!this.props.violationTypeId) {
					return [];
				}

				return await getViolationTypeHoldersList(
					at,
					this.props.violationTypeId
				);
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center pb-3 mt-5">
					<h3>{this.props.t("violationEffect")}</h3>
				</div>
				<div className="container-fluid pb-3">
					<DataGrid
						ref={this.dataGridRef}
						className={
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en"
						}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						columnWidth={"auto"}
						onSelectionChanged={this.onSelectionChanged}
					>
						<ColumnChooser enabled={true} allowSearch={true} />

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Column
							type="selection"
							cellRender={this.customizeCellCheckbox}
						/>

						<Column
							dataField={SectionNameKey}
							caption={this.props.t("effectLevel")}
							alignment={columnAlign}
						></Column>
					</DataGrid>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	triggerNotification,
	setLoading,
})(translationHook(ViolationTypeHoldersList));
