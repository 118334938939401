import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary, ButtonSecondary } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Switches } from "mui-rff";
import { Link, navigate } from "@reach/router";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { getViolationCategoryDetails } from "../../../apis/system/violations/index";

class ViolationCategoryForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initValues: null,
		};
	}

	onSectionSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.violationCatId
				? "/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Add_Violation_Category"
				: "/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Upd_Violation_Category",
			bodyData = {
				Violation_Category_Name_AR: values.Violation_Category_Name_AR,
				Violation_Category_Name_EN: values.Violation_Category_Name_EN,
				Active_Status_Id: values.Active_Status_Id ? 1 : 0,
			};

		if (this.props.violationCatId) {
			bodyData.Violation_Category_Id = this.props.violationCatId;
		}

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status && !this.props.violationCatId) {
			navigate(
				`/violation-categories/edit-category/${response.data.Data.Violation_Category_Id}`
			);
		}

		if (this.props.editMood) {
			this.props.triggerDialogForm(false);
		}

		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);
	};

	componentDidMount() {
		let auth = this.props.currentUser.Data.access_token;

		if (this.props.violationCatId) {
			(async () => {
				const categoryDetails = await getViolationCategoryDetails(
					auth,
					this.props.violationCatId
				);

				if (categoryDetails?.data?.Status) {
					this.setState({
						initValues: {
							Violation_Category_Id:
								categoryDetails?.data?.Data
									?.Violation_Category_Id,
							Violation_Category_Name_AR:
								categoryDetails?.data?.Data
									?.Violation_Category_Name_AR,
							Violation_Category_Name_EN:
								categoryDetails?.data?.Data
									?.Violation_Category_Name_EN,
							Active_Status_Id:
								categoryDetails?.data?.Data?.Active_Status_Id,
						},
					});
				}
			})();
		}
	}

	render() {
		let { initValues } = this.state;

		const validate = (values) => {
			const errors = {};

			if (!values.Violation_Category_Name_AR) {
				errors.Violation_Category_Name_AR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.Violation_Category_Name_EN) {
				errors.Violation_Category_Name_EN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			return errors;
		};

		return (
			<>
				<div className="d-flex justify-content-between align-items-center pb-5">
					<h3>{this.props.t("manageViolationCategory")}</h3>
					<ButtonSecondary
						variant="outlined"
						endIcon={<ExitToAppIcon />}
						component={Link}
						to="/violation-categories"
						size="large"
					>
						{this.props.t("exitToCategorieslist")}
					</ButtonSecondary>
				</div>

				<Form
					onSubmit={this.onSectionSubmit}
					initialValues={
						initValues
							? initValues
							: {
									Active_Status_Id: true,
									Violation_Category_Name_AR: "",
									Violation_Category_Name_EN: "",
							  }
					}
					validate={validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="sliderform"
							className={`row`}
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12} md={6}>
								<TextField
									disabled
									label={this.props.t("categoryID")}
									className="mb-2"
									name="Violation_Category_Id"
								/>
							</Col>

							<Col
								xs={12}
								md={6}
								className={`${
									this.props.viewMood ? "read-only" : ""
								}`}
							>
								<Switches
									disabled={this.props.viewMood}
									color="primary"
									name="Active_Status_Id"
									data={{
										label: `${this.props.t(
											"serviceStatus"
										)}`,
										value: `${this.props.t(
											"serviceStatus"
										)}`,
									}}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("categoryNameAR")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									name="Violation_Category_Name_AR"
									required={true}
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("categoryNameEN")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									name="Violation_Category_Name_EN"
									required={true}
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col
								className="my-4 justify-content-start d-flex"
								xs={12}
							>
								{!this.props.viewMood && (
									<ButtonPrimary
										color="primary"
										variant="contained"
										disabled={
											submitting || this.props.viewMood
										}
										type="submit"
									>
										{this.props.t("save")}
									</ButtonPrimary>
								)}
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ViolationCategoryForm));
