import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import { ButtonPrimary, ButtonSecondary } from "../../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import FilesUpload from "../../../UI/FileUpload/UploadFile";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import { Form, Field } from "react-final-form";
import { TextField } from "mui-rff";
import { downloadRequestAttachment } from "../../../../apis/requests/info/downloadRequestAttachment";

class Document extends Component {
	state = {
		filesData: [],
		documentTypes: [],
		documentSizes: [],
		documentStampsTypes: [],
		showButton: true,
		templateVariables: [],
		showVariables: false,
		elements: [],
		initValues: {},
	};

	// Docs Types
	getDocumentTypes = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/SYSTEM/DDL/GET_DOCUMENT_TYPES";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let documentTypes = [];

			response.data.Data.map((item, index) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Document_Type_Name_AR
						: item.Document_Type_Name_EN;

				return documentTypes.push({
					key: index,
					value: item.Document_Type_Id,
					label,
				});
			});

			this.setState({ documentTypes });
		}
	};

	// Docs Sizes
	getDocumentSizes = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/SYSTEM/DDL/GET_DOCUMENT_SIZES";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let documentSizes = [];

			response.data.Data.map((item, index) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Document_Size_Name_AR
						: item.Document_Size_Name_EN;

				return documentSizes.push({
					key: index,
					value: item.Document_Size_Id,
					label,
				});
			});

			this.setState({ documentSizes });
		}
	};

	// Docs Stamps Types
	getDocumentStampsTypes = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/SYSTEM/DDL/GET_DOCUMENT_STAMPS_TYPES";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let documentStampsTypes = [];

			response.data.Data.map((item, index) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Document_Stamp_Type_Name_AR
						: item.Document_Stamp_Type_Name_EN;

				return documentStampsTypes.push({
					key: index,
					value: item.Document_Stamp_Type_Id,
					label,
				});
			});

			this.setState({ documentStampsTypes });
		}
	};

	handleDocumentTypeChange = async (e, element) => {
		let event = { ...e };

		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_DOCUMENT_FILE_TYPE";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code: element.File_Code,
				Document_Type_Id: event.target.value,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);
		if (response.data.Status) {
			let data = this.state.filesData.map((ele) => {
				if (ele.File_Code === element.File_Code) {
					ele["Document_Type_Id"] = event.target.value;
					return ele;
				} else {
					return ele;
				}
			});
			this.setState({
				filesData: [...data],
			});
		}
	};
	handleDocumentSizeChange = async (e, element) => {
		let event = { ...e };

		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_DOCUMENT_FILE_SIZE";
		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code: element.File_Code,
				Document_Size_Id: event.target.value,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let data = this.state.filesData.map((ele) => {
				if (ele.File_Code === element.File_Code) {
					ele["Document_Size_Id"] = event.target.value;
					return ele;
				} else {
					return ele;
				}
			});
			this.setState({
				filesData: data,
			});
		}
	};

	handleDocumentStampsTypesChange = async (e, element) => {
		let event = { ...e };

		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_DOCUMENT_FILE_STAMP";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code: element.File_Code,
				Document_Stamp_Type_Id: event.target.value,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let data = this.state.filesData.map((ele) => {
				if (ele.File_Code === element.File_Code) {
					ele["Document_Stamp_Type_Id"] = event.target.value;
					return ele;
				} else {
					return ele;
				}
			});
			this.setState({
				filesData: data,
			});
		}
	};
	//
	documentInfoListsData = (e) => {
		return [
			{
				data: this.state.documentTypes,
				handleChange: this.handleDocumentTypeChange,
			},
			{
				data: this.state.documentSizes,
				handleChange: this.handleDocumentSizeChange,
			},
			{
				data: this.state.documentStampsTypes,
				handleChange: this.handleDocumentStampsTypesChange,
			},
		];
	};

	handleSetFilesData = (data) => {
		this.setState({ filesData: data });
	};

	getRequestDocumentFiles = async () => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_DOCUMENTS_FILES_LIST";
		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({ filesData: response.data.Data });
		}
	};

	approveRequestTaskDocumentFile = async ({ File_Code }) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"API/ADMINISTRATION/REQUESTS/MANAGEMENT/APPROVING_REQUEST_TASK_DOCUMENT_FILE";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({ filesData: response.data.Data });
		}
	};

	unApproveRequestTaskDocumentFile = async ({ File_Code }) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/UNAPPROVING_REQUEST_TASK_DOCUMENT_FILE";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({ filesData: response.data.Data });
		}
	};

	deleteRequestTaskDocumentFile = async ({ File_Code }) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DELETE_REQUEST_TASK_DOCUMENT_FILE";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		return response;
	};

	componentDidMount() {
		this.getRequestDocumentFiles();
		this.getDocumentTypes();
		this.getDocumentSizes();
		this.getDocumentStampsTypes();
	}

	render() {
		return (
			<>
				{this.state.filesData && (
					<Row>
						{((this.props.task === "approve" &&
							this.state.filesData.length > 0) ||
							this.props.task === "documentAudit" ||
							this.props.task === "documentApprove") && (
							<Col xs={12}>
								<h4 className="my-3">
									{this.props.t("drawingAndDocuments")}
								</h4>
							</Col>
						)}
						<Col xs={12} md={8}>
							<FilesUpload
								view={
									this.props.task === "documentApprove" ||
									this.props.task === "approve"
										? true
										: false
								}
								single={true}
								params={{
									Request_Id: this.props.requestId,
									Request_Task_Id: this.props.requestTaskId,
									Temp_Action_Id: this.props.tempId,
								}}
								hasInfo={true}
								documentInfoListsData={this.documentInfoListsData()}
								downloadCB={downloadRequestAttachment}
								deleteCB={this.deleteRequestTaskDocumentFile}
								approveCB={this.approveRequestTaskDocumentFile}
								unApproveCB={
									this.unApproveRequestTaskDocumentFile
								}
								endPoint="API/ADMINISTRATION/REQUESTS/MANAGEMENT/ATTACED_REQUEST_TASK_DOCUMENT_FILE"
								filesData={this.state.filesData}
								handleSetFilesData={this.handleSetFilesData}
							/>
						</Col>
					</Row>
				)}
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(Document));
