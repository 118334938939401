import apiConfig from "../../apiConfig";

export const getViolationCategories = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Get_Violations_Categories_List",
		{
			pageCount: 10000,
			PageIndex: 1,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data;
};

export const getViolationCategoryDetails = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Get_Violation_Category",
		{
			Violation_Category_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response;
};

export const setViolationCategoryStatus = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Set_Violation_Category_Status",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data;
};

export const deleteViolationCategory = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Del_Violation_Category",
		{
			Violation_Category_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};

export const getViolationTypes = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Get_Violations_Types_List",
		{
			pageCount: 10000,
			PageIndex: 1,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data;
};

export const setViolationTypeStatus = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Set_Violation_Type_Status",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data;
};

export const deleteViolationType = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Del_Violation_Type",
		{
			Violation_Type_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};

export const getViolationTypeDetails = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Get_Violation_Type",
		{
			Violation_Type_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response;
};

export const deleteViolationHolder = async (
	at,
	Violation_Type_Id,
	Violation_Type_Holder_Id
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Del_Violation_Type_Holder",
		{
			Violation_Type_Id,
			Violation_Type_Holder_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};

export const addViolationHolder = async (
	at,
	Violation_Type_Id,
	Violation_Holder_Id
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Add_Violation_Type_Holder",
		{
			Violation_Type_Id,
			Violation_Holder_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};

export const getViolationTypeHoldersList = async (at, Violation_Type_Id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Get_Violation_Type_Holders_List",
		{
			Violation_Type_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data;
};

export const getViolationsList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Get_Violations_List",
		{
			pageCount: 10000,
			PageIndex: 1,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response;
};

export const getViolationDetails = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Get_Violation_Details",
		{
			Violation_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response;
};

export const deleteViolationAttachment = async (
	at,
	Violation_Id,
	File_Code
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Del_Violation_File",
		{
			Violation_Id,
			File_Code,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};

export const getBlocksList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Get_Blocking_List",
		{
			pageCount: 10000,
			PageIndex: 1,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response;
};

export const getBlockDetails = async (at, id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Get_Blocking_Details",
		{
			Client_Blocking_Id: id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response;
};

export const deleteBlockAttachment = async (
	at,
	Client_Blocking_Id,
	File_Code
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/VIOLATIONS/BLOCKING/Del_Blocking_File",
		{
			Client_Blocking_Id,
			File_Code,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};
