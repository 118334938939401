import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../../translationHook";
import apiConfig from "../../../../apis/apiConfig";
import { ButtonPrimary } from "../../../UI/Buttons";
import { Form } from "react-final-form";
import { TextField, Switches, Select } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import style from "../../../departments/jobs/DepartmentJob.module.scss";

// import DepartmentJobSectionsList from "./DepartmentJobSectionsList";
// import DepartmentJobGroupWorkersNameList from "./DepartmentJobGroupWorkersNameList";

import VisitViolationsList from "./VisitViolationsList";
import ClientViolationsList from "./ClientViolationsList";
import ProjectViolationsList from "./ProjectViolationsList";
import PlotViolationsList from "./PlotViolationsList";
import SysPlotViolationsList from "./SysPlotViolationsList";
import SysProjectViolationsList from "./SysProjectViolationsList";
import SysClientViolationsList from "./SysClientViolationsList";

import ViolationDetailForm from "../../../system/violations/ViolationDetailForm";

class ViolationsDetails extends Component {
	state = {
		showForm: false,
		showSectionsList: false,
		visitViolations: [],
		clientViolations: [],
		projectViolations: [],
		plotViolations: [],
		sysPlotViolations: [],
		sysProjectViolations: [],
		sysClientsViolations: [],
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			this.setState({
				visitViolations: this.props.visitViolations,
				clientViolations: this.props.violationData.Client_Violations,
				plotViolations: this.props.violationData.Plot_Violations,
				projectViolations: this.props.violationData.Project_Violations,
				sysPlotViolations:
					this.props.violationData.ShjSys_Plot_Violations,
				sysProjectViolations:
					this.props.violationData.ShjSys_Project_Violations,

				sysClientsViolations:
					this.props.violationData.ShjSys_Client_Violations,
			});
		}
	}

	componentDidMount() {
		if (this.props.visitViolations) {
			this.setState({ visitViolations: this.props.visitViolations });
		}

		if (this.props.violationData.Client_Violations) {
			this.setState({
				clientViolations: this.props.violationData.Client_Violations,
			});
		}

		if (this.props.violationData.Project_Violations) {
			this.setState({
				projectViolations: this.props.violationData.Project_Violations,
			});
		}

		if (this.props.violationData.Plot_Violations) {
			this.setState({
				plotViolations: this.props.violationData.Plot_Violations,
			});
		}

		if (this.props.violationData.ShjSys_Plot_Violations) {
			this.setState({
				sysPlotViolations:
					this.props.violationData.ShjSys_Plot_Violations,
			});
		}

		if (this.props.violationData.ShjSys_Project_Violations) {
			this.setState({
				sysProjectViolations:
					this.props.violationData.ShjSys_Project_Violations,
			});
		}

		if (this.props.violationData.ShjSys_Client_Violations) {
			this.setState({
				sysClientsViolations:
					this.props.violationData.ShjSys_Client_Violations,
			});
		}
	}

	handleAddViolation = (res) => {
		if (res.data.Status) {
			this.setState({ visitViolations: res.data.Data, showForm: false });
		}
	};

	render() {
		let { initValues } = this.props;

		const validate = (values) => {
			const errors = {};

			return errors;
		};

		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className="d-flex justify-content-between align-items-center pb-5">
						<h3>{this.props.t("violations")}</h3>
						<ButtonPrimary
							variant={
								!this.state.showForm ? "contained" : "outlined"
							}
							onClick={() =>
								this.setState({
									showForm: !this.state.showForm,
								})
							}
						>
							{!this.state.showForm &&
								this.props.t("addViolation")}
							{this.state.showForm && this.props.t("cancel")}
						</ButtonPrimary>
					</div>
				)}

				{this.state.showForm && (
					<ViolationDetailForm
						isStepForm={true}
						requestId={this.props.requestId}
						requestTaskId={this.props.requestTaskId}
						submitCB={this.handleAddViolation}
						violationInfo={this.props.violationInfo}
					/>
				)}

				<div className={style.depJobTabs}>
					<Tab.Container
						id="department-jobs-tabs"
						defaultActiveKey={0}
					>
						<Nav variant="pills" className={style.tabsPills}>
							<Nav.Item className={style.tabsItems}>
								<Nav.Link
									eventKey="0"
									className={style.tabsLink}
								>
									{this.props.t("visitViolations")}
								</Nav.Link>
							</Nav.Item>
							{this.state.clientViolations?.length > 0 && (
								<Nav.Item className={style.tabsItems}>
									<Nav.Link
										eventKey="clientViolations"
										className={style.tabsLink}
									>
										{this.props.t("clientViolations")}
									</Nav.Link>
								</Nav.Item>
							)}

							{this.state.projectViolations?.length > 0 && (
								<Nav.Item className={style.tabsItems}>
									<Nav.Link
										eventKey="projectViolations"
										className={style.tabsLink}
									>
										{this.props.t("projectViolations")}
									</Nav.Link>
								</Nav.Item>
							)}

							{this.state.plotViolations?.length > 0 && (
								<Nav.Item className={style.tabsItems}>
									<Nav.Link
										eventKey="plotViolations"
										className={style.tabsLink}
									>
										{this.props.t("plotViolations")}
									</Nav.Link>
								</Nav.Item>
							)}

							{this.state.sysPlotViolations?.length > 0 && (
								<Nav.Item className={style.tabsItems}>
									<Nav.Link
										eventKey="sysPlotViolations"
										className={style.tabsLink}
									>
										{this.props.t("sysPlotViolations")}
									</Nav.Link>
								</Nav.Item>
							)}

							{this.state.sysProjectViolations?.length > 0 && (
								<Nav.Item className={style.tabsItems}>
									<Nav.Link
										eventKey="sysProjectViolations"
										className={style.tabsLink}
									>
										{this.props.t("sysProjectViolations")}
									</Nav.Link>
								</Nav.Item>
							)}

							{this.state.sysClientsViolations?.length > 0 && (
								<Nav.Item className={style.tabsItems}>
									<Nav.Link
										eventKey="sysClientsViolations"
										className={style.tabsLink}
									>
										{this.props.t("sysClientsViolations")}
									</Nav.Link>
								</Nav.Item>
							)}
						</Nav>

						<Tab.Content>
							<Tab.Pane eventKey={0}>
								<VisitViolationsList
									visitViolations={this.state.visitViolations}
									requestId={this.props.requestId}
									requestTaskId={this.props.requestTaskId}
								/>
							</Tab.Pane>

							{this.state.clientViolations?.length > 0 && (
								<Tab.Pane eventKey="clientViolations">
									<ClientViolationsList
										clientViolations={
											this.state.clientViolations
										}
									/>
								</Tab.Pane>
							)}

							{this.state.projectViolations?.length > 0 && (
								<Tab.Pane eventKey="projectViolations">
									<ProjectViolationsList
										projectViolations={
											this.state.projectViolations
										}
									/>
								</Tab.Pane>
							)}

							{this.state.plotViolations?.length > 0 && (
								<Tab.Pane eventKey="plotViolations">
									<PlotViolationsList
										plotViolations={
											this.state.plotViolations
										}
									/>
								</Tab.Pane>
							)}

							{this.state.sysPlotViolations?.length > 0 && (
								<Tab.Pane eventKey="sysPlotViolations">
									<SysPlotViolationsList
										sysPlotViolations={
											this.state.sysPlotViolations
										}
									/>
								</Tab.Pane>
							)}

							{this.state.sysProjectViolations?.length > 0 && (
								<Tab.Pane eventKey="sysProjectViolations">
									<SysProjectViolationsList
										sysProjectViolations={
											this.state.sysProjectViolations
										}
									/>
								</Tab.Pane>
							)}

							{this.state.sysClientsViolations?.length > 0 && (
								<Tab.Pane eventKey="sysClientsViolations">
									<SysClientViolationsList
										sysClientsViolations={
											this.state.sysClientsViolations
										}
									/>
								</Tab.Pane>
							)}
						</Tab.Content>
					</Tab.Container>
				</div>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ViolationsDetails));
