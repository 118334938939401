import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary, ButtonSecondary } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Switches, Select } from "mui-rff";
import { Link, navigate } from "@reach/router";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { getViolationTypeDetails } from "../../../apis/system/violations/index";

import ViolationTypeHoldersList from "./ViolationTypeHoldersList";

class ViolationTypeForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initValues: null,
			dataLoaded: false,
			violationCatsList: [],
		};
	}

	getViolationCatsList = async () => {
		let arr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_VIOLATIONS_CATEGORIES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				arr.push({
					value: item.Record_Id,
					label,
				});
			});

			this.setState({ violationCatsList: arr });
		}
	};

	onSectionSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.violationTypeId
				? "/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Add_Violation_Type"
				: "/API/ADMINISTRATION/VIOLATIONS/LOOKUPS/Upd_Violation_Type",
			bodyData = {
				Violation_Type_Name_AR: values.Violation_Type_Name_AR,
				Violation_Type_Name_EN: values.Violation_Type_Name_EN,
				Active_Status_Id: values.Active_Status_Id ? 1 : 0,
				Violation_Category_Id: values.Violation_Category_Id,
			};

		if (this.props.violationTypeId) {
			bodyData.Violation_Type_Id = this.props.violationTypeId;
		}

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			if (!this.props.violationTypeId) {
				navigate(
					`/violation-types/edit-type/${response.data.Data.Violation_Type_Id}`
				);
			}
		}

		if (this.props.editMood) {
			this.props.triggerDialogForm(false);
		}

		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);
	};

	componentDidMount() {
		let auth = this.props.currentUser.Data.access_token;

		Promise.all([this.getViolationCatsList()]).then(() => {
			this.setState({ dataLoaded: true });
		});

		if (this.props.violationTypeId) {
			(async () => {
				const typeDetails = await getViolationTypeDetails(
					auth,
					this.props.violationTypeId
				);

				if (typeDetails?.data?.Status) {
					this.setState({
						initValues: {
							Violation_Category_Id:
								typeDetails?.data?.Data?.Violation_Category_Id,
							Violation_Type_Name_AR:
								typeDetails?.data?.Data?.Violation_Type_Name_AR,
							Violation_Type_Name_EN:
								typeDetails?.data?.Data?.Violation_Type_Name_EN,
							Active_Status_Id:
								typeDetails?.data?.Data?.Active_Status_Id,
						},
					});
				}
			})();
		}
	}

	render() {
		let { initValues } = this.state;

		const validate = (values) => {
			const errors = {};

			if (!values.Violation_Type_Name_AR) {
				errors.Violation_Type_Name_AR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.Violation_Type_Name_EN) {
				errors.Violation_Type_Name_EN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			return errors;
		};

		if (!this.state.dataLoaded) {
			return (
				<p
					style={{
						textAlign: "start",
						padding: "10px",
					}}
				>
					{this.props.t("loadingClientData")}
				</p>
			);
		} else {
			return (
				<>
					<div className="d-flex justify-content-between align-items-center pb-5">
						<h3>{this.props.t("manageViolationType")}</h3>
						<ButtonSecondary
							variant="outlined"
							endIcon={<ExitToAppIcon />}
							component={Link}
							to="/violation-types"
							size="large"
						>
							{this.props.t("exitToTypeslist")}
						</ButtonSecondary>
					</div>

					<Form
						onSubmit={this.onSectionSubmit}
						initialValues={
							initValues
								? initValues
								: {
										Active_Status_Id: true,
										Violation_Type_Name_AR: "",
										Violation_Type_Name_EN: "",
										Violation_Category_Id: "",
								  }
						}
						validate={validate}
						render={({
							handleSubmit,
							form,
							submitting,
							pristine,
							values,
						}) => (
							<form
								id="sliderform"
								className={`row`}
								onSubmit={handleSubmit}
								noValidate
							>
								<Col xs={12} md={6}>
									<TextField
										disabled
										label={this.props.t("violationTypeID")}
										className="mb-2"
										name="Violation_Type_Id"
									/>
								</Col>

								<Col xs={12} md={6}>
									<Field name="Violation_Category_Id">
										{(fieldProps) => (
											<Select
												label={this.props.t(
													"violationCategory"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
												data={
													this.state.violationCatsList
												}
												name="Violation_Category_Id"
												onChange={(e) => {
													fieldProps.input.onChange(
														e.target.value
													);
												}}
												showError={({
													meta: {
														submitFailed,
														invalid,
													},
												}) => {
													return (
														submitFailed && invalid
													);
												}}
											></Select>
										)}
									</Field>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("typeNameAR")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										name="Violation_Type_Name_AR"
										required={true}
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("typeNameEN")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										name="Violation_Type_Name_EN"
										required={true}
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col
									xs={12}
									md={6}
									className={`${
										this.props.viewMood ? "read-only" : ""
									}`}
								>
									<Switches
										disabled={this.props.viewMood}
										color="primary"
										name="Active_Status_Id"
										data={{
											label: `${this.props.t(
												"serviceStatus"
											)}`,
											value: `${this.props.t(
												"serviceStatus"
											)}`,
										}}
									/>
								</Col>

								<Col
									className="my-4 justify-content-start d-flex"
									xs={12}
								>
									{!this.props.viewMood && (
										<ButtonPrimary
											color="primary"
											variant="contained"
											disabled={
												submitting ||
												this.props.viewMood
											}
											type="submit"
										>
											{this.props.t("save")}
										</ButtonPrimary>
									)}
								</Col>
							</form>
						)}
					/>

					{this.props.violationTypeId && (
						<ViolationTypeHoldersList
							violationTypeId={this.props.violationTypeId}
						/>
					)}
				</>
			);
		}
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ViolationTypeForm));
