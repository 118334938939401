import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary, ButtonSecondary, ButtonDanger } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Autocomplete, Select } from "mui-rff";
import { Link, navigate } from "@reach/router";
import moment from "moment";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import {
	getViolationTypeDetails,
	getViolationDetails,
} from "../../../apis/system/violations/index";

import ViolationAttachmentsList from "./ViolationAttachmentsList";

class ViolationDetailForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initValues: null,
			dataLoaded: false,
			violationTypesList: [],
			Attached_Files: [],
			clientsList: [],
			violationAreasList: [],
			Show_Area: 0,
			Show_Plot_No: 0,
			Show_Project_No: 0,
			Enable_Edit_Violation: 0,
			Violation_Attachments: [],
			lockFields: true,
		};
	}

	selectFiles = (event, field) => {
		let filesData = this.state.Attached_Files;

		for (const file in event.target.files) {
			if (Object.hasOwnProperty.call(event.target.files, file)) {
				const element = event.target.files[file];
				element["Can_Delete"] = 1;
				element[
					"Key"
				] = `attach_${file}_${element.lastModified}_${element.size}`;
				filesData.push(element);
			}
		}

		this.setState({
			Attached_Files: filesData,
		});
	};

	getClientsList = async () => {
		let at = this.props.currentUser.Data.access_token,
			arr = [];

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Get_Clients_Profiles_List",
			{},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Client_Name_AR
						: item.Client_Name_EN;

				arr.push({
					value: item.Client_Profile_Id,
					label,
				});
			});

			this.setState({ clientsList: arr });
		}
	};

	getViolationTypesList = async () => {
		let at = this.props.currentUser.Data.access_token,
			arr = [];

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Get_Violation_Types_List",
			{},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Violation_Type_Name_AR
						: item.Violation_Type_Name_EN;

				arr.push({
					value: item.Violation_Type_Id,
					label,
				});
			});

			this.setState({ violationTypesList: arr });
		}
	};

	getViolationAreasList = async () => {
		let at = this.props.currentUser.Data.access_token,
			arr = [];

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Get_Violation_Areas_List",
			{},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Area_Name_AR
						: item.Area_Name_EN;

				arr.push({
					value: item.Area_Id,
					label,
				});
			});

			this.setState({ violationAreasList: arr });
		}
	};

	getVaiolationTypeInputs = async (Violation_Type_Id) => {
		let at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Get_Violation_Type_Inputs",
			{ Violation_Type_Id },
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({
				Show_Area: response.data.Data.Show_Area,
				Show_Plot_No: response.data.Data.Show_Plot_No,
				Show_Project_No: response.data.Data.Show_Project_No,
			});
		}
	};

	getClientDetails = async ({ value }) => {
		let at = this.props.currentUser.Data.access_token;

		if (this.props.violationId) {
			return;
		}

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Get_Client_Profile_Info",
			{ Client_Profile_Id: value },
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({
				initValues: {
					Dealing_Type_Name_AR:
						response.data.Data[0].Dealing_Type_Name_AR,
					Dealing_Type_Name_EN:
						response.data.Data[0].Dealing_Type_Name_EN,
					Client_License_No: response.data.Data[0].Client_License_No,
					Client_License_Expired_Date:
						response.data.Data[0].Client_License_Expired_Date,
					Violation_Date: moment().format("YYYY-MM-DD"),
					Client_Profile_Id: value,
				},
			});
		}
	};

	cancelViolation = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Cancel_Violation";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{ Violation_Id: this.props.violationId },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);

					let vDetails = res?.data?.Data?.Violation_Data;
					vDetails["Violation_Date"] = moment(
						vDetails.Violation_Data
					).format("YYYY-MM-DD");

					this.setState({
						initValues: vDetails,
						Violation_Attachments:
							res?.data?.Data?.Violation_Attachments,
						Show_Area: res?.data?.Data?.Shown_Holders?.Show_Area,
						Show_Plot_No:
							res?.data?.Data?.Shown_Holders?.Show_Plot_No,
						Show_Project_No:
							res?.data?.Data?.Shown_Holders?.Show_Project_No,
						Enable_Edit_Violation:
							res?.data?.Data?.Enable_Edit_Violation,
					});
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);
				this.props.triggerNotification(
					true,
					err.response ? err.response.data : err.data
				);

				return err;
			});
	};

	closeViolation = async () => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Close_Violation";

		this.props.setLoading(true, "dark");

		await apiConfig
			.post(
				url,
				{ Violation_Id: this.props.violationId },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.props.setLoading(false);
					this.props.triggerNotification(true, res.data);

					let vDetails = res?.data?.Data?.Violation_Data;
					vDetails["Violation_Date"] = moment(
						vDetails.Violation_Data
					).format("YYYY-MM-DD");

					this.setState({
						initValues: vDetails,
						Violation_Attachments:
							res?.data?.Data?.Violation_Attachments,
						Show_Area: res?.data?.Data?.Shown_Holders?.Show_Area,
						Show_Plot_No:
							res?.data?.Data?.Shown_Holders?.Show_Plot_No,
						Show_Project_No:
							res?.data?.Data?.Shown_Holders?.Show_Project_No,
						Enable_Edit_Violation:
							res?.data?.Data?.Enable_Edit_Violation,
					});
				} else {
					throw res;
				}
				return res;
			})
			.catch((err) => {
				this.props.setLoading(false);
				this.props.triggerNotification(
					true,
					err.response ? err.response.data : err.data
				);

				return err;
			});
	};

	handleUploadAttachments = async () => {
		let at = this.props.currentUser.Data.access_token,
			bodyFormData = new FormData(),
			url =
				"/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Add_Violation_Files";

		bodyFormData.append(
			"PARAMETERS",
			JSON.stringify({ Violation_Id: this.props.violationId })
		);

		this.state.Attached_Files.forEach((file) => {
			bodyFormData.append("Attached_Files[]", file);
		});

		const res = await apiConfig.post(url, bodyFormData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});
		return res;
	};

	handleUpdateDetails = async (values) => {
		let at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Upd_Violation",
			bodyData = {
				Violation_Id: this.props.violationId,
				Client_Profile_Id: values.Client_Profile_Id,
				Violation_Type_Id: values.Violation_Type_Id,
				Violation_Value: values.Violation_Value,
				Area_Id: values.Area_Id,
				Plot_No: values.Plot_No,
				Project_No: values.Project_No,
				Violation_Notes: values.Violation_Notes,
			};

		const res = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		return res;
	};

	onSectionSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			bodyFormData = new FormData(),
			url =
				!this.props.violationId && !this.props.isStepForm
					? "/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Add_Violation"
					: this.props.isStepForm
					? "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Task_Inspection_Violation"
					: "/API/ADMINISTRATION/VIOLATIONS/MANAGEMENT/Upd_Violation",
			bodyData = {
				Client_Profile_Id: values.Client_Profile_Id,
				Violation_Type_Id: values.Violation_Type_Id,
				Violation_Value: values.Violation_Value,
				Area_Id: values.Area_Id,
				Plot_No: values.Plot_No,
				Project_No: values.Project_No,
				Violation_Notes: values.Violation_Notes,
			};

		this.props.setLoading(true, "dark");

		// update
		if (this.props.violationId) {
			Promise.all([
				this.handleUpdateDetails(values),
				this.handleUploadAttachments(),
			])
				.then((res) => {
					//  details res
					if (res[0].data.Status) {
						let vDetails = res[0]?.data?.Data?.Violation_Data;
						vDetails["Violation_Date"] = moment(
							vDetails.Violation_Data
						).format("YYYY-MM-DD");

						this.props.setLoading(false);
						this.props.triggerNotification(true, res[0].data);

						this.setState({
							initValues: vDetails,
							Violation_Attachments:
								res[0]?.data?.Data?.Violation_Attachments,
							Show_Area:
								res[0]?.data?.Data?.Shown_Holders?.Show_Area,
							Show_Plot_No:
								res[0]?.data?.Data?.Shown_Holders?.Show_Plot_No,
							Show_Project_No:
								res[0]?.data?.Data?.Shown_Holders
									?.Show_Project_No,
							Enable_Edit_Violation:
								res[0]?.data?.Data?.Enable_Edit_Violation,
							dataLoaded: true,
							Attached_Files: [],
						});
					} else {
						throw res[0];
					}

					// attachment res
					if (res[1].data.Status) {
						this.setState({
							Violation_Attachments: res[1]?.data?.Data,
						});
					} else {
						throw res[1];
					}

					return res;
				})
				.catch((error) => {
					this.props.setLoading(false);

					this.props.triggerNotification(
						true,
						error.response ? error.response.data : error.data
					);

					return error.response;
				});
		}

		// add
		if (!this.props.violationId) {
			// steps form
			if (this.props.isStepForm) {
				bodyData["Request_Task_Id"] = this.props.requestTaskId;
				bodyData["Request_Id"] = this.props.requestId;
			}

			bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

			this.state.Attached_Files.forEach((file) => {
				bodyFormData.append("Attached_Files[]", file);
			});

			await apiConfig
				.post(url, bodyFormData, {
					headers: {
						Authorization: `Bearer ${at}`,
					},
				})
				.then((res) => {
					if (res.data.Status) {
						this.props.setLoading(false);
						this.props.triggerNotification(true, res.data);

						if (!this.props.isStepForm) {
							this.props.triggerDialogForm(false);
							navigate(
								`/manage-violations/violation-info/${res.data.Data.Data.Violation_Data.Violation_Id}`
							);
						} else {
							this.props.submitCB(res);
							form.restart();
						}
					} else {
						throw res;
					}

					return res;
				})
				.catch((error) => {
					this.props.setLoading(false);

					this.props.triggerNotification(
						true,
						error.response ? error.response.data : error.data
					);

					return error.response;
				});
		}
	};

	componentDidMount() {
		let auth = this.props.currentUser.Data.access_token;

		Promise.all([
			this.getClientsList(),
			this.getViolationTypesList(),
			this.getViolationAreasList(),
		]).then(() => {
			if (!this.props.violationId) {
				this.setState({
					dataLoaded: true,
					lockFields: false,
					Enable_Edit_Violation: 1,
				});
			}
		});

		if (this.props.isStepForm) {
			this.setState({ initValues: this.props.violationInfo });
		}

		if (this.props.violationId) {
			(async () => {
				const details = await getViolationDetails(
					auth,
					this.props.violationId
				).then((res) => {
					if (res?.data?.Status) {
						let vDetails = res?.data?.Data?.Violation_Data;
						vDetails["Violation_Date"] = moment(
							vDetails.Violation_Data
						).format("YYYY-MM-DD");

						this.setState({
							initValues: vDetails,
							Violation_Attachments:
								res?.data?.Data?.Violation_Attachments,
							Show_Area:
								res?.data?.Data?.Shown_Holders?.Show_Area,
							Show_Plot_No:
								res?.data?.Data?.Shown_Holders?.Show_Plot_No,
							Show_Project_No:
								res?.data?.Data?.Shown_Holders?.Show_Project_No,
							Enable_Edit_Violation:
								res?.data?.Data?.Enable_Edit_Violation,
							dataLoaded: true,
						});
					}
				});
			})();
		}
	}

	render() {
		let { initValues } = this.state;

		const validate = (values) => {
			const errors = {};

			return errors;
		};

		if (!this.state.dataLoaded) {
			return (
				<p
					style={{
						textAlign: "start",
						padding: "10px",
					}}
				>
					{this.props.t("loadingClientData")}
				</p>
			);
		} else {
			return (
				<>
					{this.props.violationId && (
						<div className="d-flex justify-content-between align-items-center pb-5">
							<h3>{this.props.t("manageViolation")}</h3>

							<ButtonSecondary
								variant="outlined"
								endIcon={<ExitToAppIcon />}
								component={Link}
								to="/manage-violations"
								size="large"
							>
								{this.props.t("exitToviolationslist")}
							</ButtonSecondary>
						</div>
					)}

					<Form
						onSubmit={this.onSectionSubmit}
						initialValues={
							initValues
								? initValues
								: {
										Client_Profile_Id: "",
										Violation_Type_Id: "",
										Violation_Value: "",
										Area_Id: "",
										Plot_No: "",
										Project_No: "",
										Violation_Notes: "",
										Violation_Date:
											moment().format("YYYY-MM-DD"),
								  }
						}
						validate={validate}
						render={({
							handleSubmit,
							form,
							submitting,
							pristine,
							values,
						}) => (
							<form
								id="sliderform"
								className={`row pt-3`}
								onSubmit={handleSubmit}
								noValidate
							>
								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("violationID")}
										className="mb-2"
										name="Violation_Id"
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("violationNo")}
										className="mb-2"
										name="Violation_No"
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("violationStatus")}
										className="mb-2"
										name={
											this.props.i18n.language === "ar"
												? "Violations_Status_Name_AR"
												: "Violations_Status_Name_EN"
										}
									/>
								</Col>

								<Col xs={12} md={8}>
									{!this.props.violationId ? (
										<Field name="Client_Profile_Id">
											{(fieldProps) => (
												<Autocomplete
													disabled={
														this.props
															.violationId ||
														this.props.isStepForm
													}
													label={this.props.t(
														"client"
													)}
													name="Client_Profile_Id"
													options={
														this.state.clientsList
													}
													getOptionValue={(option) =>
														option.value
													}
													getOptionLabel={(option) =>
														option.label
													}
													showError={({
														meta: {
															submitFailed,
															invalid,
														},
													}) => {
														return (
															submitFailed &&
															invalid
														);
													}}
													onChange={(e, v) => {
														this.getClientDetails(
															v
														);
													}}
												></Autocomplete>
											)}
										</Field>
									) : (
										<TextField
											disabled
											label={this.props.t("client")}
											className="mb-2"
											name={
												this.props.i18n.language ===
												"ar"
													? "Client_Name_AR"
													: "Client_Name_EN"
											}
										/>
									)}
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("violationDate")}
										className="mb-2"
										name="Violation_Date"
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("clientType")}
										className="mb-2"
										name={
											this.props.i18n.language === "ar"
												? "Dealing_Type_Name_AR"
												: "Dealing_Type_Name_EN"
										}
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t("licenseNo")}
										className="mb-2"
										name="Client_License_No"
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled
										label={this.props.t(
											"licenseExpireDate"
										)}
										className="mb-2"
										name="Client_License_Expired_Date"
									/>
								</Col>

								<Col xs={12} md={8}>
									<Field name="Violation_Type_Id">
										{(fieldProps) => (
											<Autocomplete
												disabled={
													this.state.lockFields ||
													!this.state
														.Enable_Edit_Violation
												}
												label={this.props.t(
													"violationType"
												)}
												name="Violation_Type_Id"
												options={
													this.state
														.violationTypesList
												}
												getOptionValue={(option) =>
													option.value
												}
												getOptionLabel={(option) =>
													option.label
												}
												showError={({
													meta: {
														submitFailed,
														invalid,
													},
												}) => {
													return (
														submitFailed && invalid
													);
												}}
												onChange={(e, v) => {
													fieldProps.input.onChange(
														v.value
													);

													this.getVaiolationTypeInputs(
														v.value
													);
												}}
											></Autocomplete>

											// <Select
											// 	disabled={
											// 		this.state.lockFields ||
											// 		!this.state
											// 			.Enable_Edit_Violation
											// 	}
											// 	label={this.props.t(
											// 		"violationType"
											// 	)}
											// 	className={`${
											// 		this.props.viewMood
											// 			? "read-only"
											// 			: ""
											// 	}`}
											// 	data={
											// 		this.state
											// 			.violationTypesList
											// 	}
											// 	name="Violation_Type_Id"
											// 	onChange={(e) => {
											// 		fieldProps.input.onChange(
											// 			e.target.value
											// 		);

											// 		this.getVaiolationTypeInputs(
											// 			e.target.value
											// 		);
											// 	}}
											// 	showError={({
											// 		meta: {
											// 			submitFailed,
											// 			invalid,
											// 		},
											// 	}) => {
											// 		return (
											// 			submitFailed && invalid
											// 		);
											// 	}}
											// ></Select>
										)}
									</Field>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled={
											this.state.lockFields ||
											!this.state.Enable_Edit_Violation
										}
										label={this.props.t("violationValue")}
										className="mb-2"
										name="Violation_Value"
									/>
								</Col>

								{!!this.state.Show_Area && (
									<Col xs={12} md={4}>
										<Field name="Area_Id">
											{(fieldProps) => (
												<Select
													disabled={
														this.state.lockFields ||
														!this.state
															.Enable_Edit_Violation
													}
													label={this.props.t("area")}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													}`}
													data={
														this.state
															.violationAreasList
													}
													name="Area_Id"
													onChange={(e) => {
														fieldProps.input.onChange(
															e.target.value
														);
													}}
													showError={({
														meta: {
															submitFailed,
															invalid,
														},
													}) => {
														return (
															submitFailed &&
															invalid
														);
													}}
												></Select>
											)}
										</Field>
									</Col>
								)}

								{!!this.state.Show_Plot_No && (
									<Col xs={12} md={4}>
										<TextField
											disabled={
												this.state.lockFields ||
												!this.state
													.Enable_Edit_Violation
											}
											label={this.props.t("plotNo")}
											className="mb-2"
											name="Plot_No"
										/>
									</Col>
								)}

								{!!this.state.Show_Project_No && (
									<Col xs={12} md={4}>
										<TextField
											disabled={
												this.state.lockFields ||
												!this.state
													.Enable_Edit_Violation
											}
											label={this.props.t("projectNo")}
											className="mb-2"
											name="Project_No"
										/>
									</Col>
								)}

								<Col xs={12}>
									<TextField
										disabled={
											this.state.lockFields ||
											!this.state.Enable_Edit_Violation
										}
										label={this.props.t("violationNotes")}
										className="mb-2"
										name="Violation_Notes"
										multiline
										rows={5}
									/>
								</Col>

								<Col xs={12}>
									<Row className="mt-5 mx-1">
										<Col
											md={6}
											className="d-flex flex-column align-items-start"
										>
											<div
												style={{
													flexGrow: 1,
												}}
											>
												<label
													style={{
														fontSize: "1rem",
														color: "#669900",
														textAlign: "right",
														display: "block",
														right: "0.5rem",
														position: "relative",
														marginBottom: "5px",
														marginTop: "-4px",
													}}
												>
													{this.props.t(
														"violationsAttachments"
													)}
												</label>

												{this.state.Attached_Files &&
												this.state.Attached_Files
													.length > 0 ? (
													<FilesUpload
														view={true}
														attachments={[
															{
																Files_Data:
																	this.state
																		.Attached_Files,
															},
														]}
														deleteCB={(params) => {
															let filterArr =
																this.state.Attached_Files.filter(
																	(f) =>
																		f.Key !==
																		params.Key
																);

															this.setState({
																Attached_Files:
																	filterArr,
															});
														}}
													/>
												) : null}
											</div>
											{!this.state.lockFields &&
												!!this.state
													.Enable_Edit_Violation && (
													<ButtonSecondary
														color="primary"
														variant="contained"
														endIcon={
															<CloudUploadIcon />
														}
														style={{
															justifyContent:
																"space-between",
															minWidth: "200px",
															marginTop: "18px",
															marginBottom:
																"18px",
														}}
													>
														{this.props.t(
															"addAttachments"
														)}
														<input
															style={{
																position:
																	"absolute",
																width: "100%",
																height: "100%",
																left: 0,
																right: 0,
																top: 0,
																bottom: 0,
																zIndex: 1,
																opacity: 0,
																fontSize: 0,
																cursor: "pointer",
															}}
															type="file"
															onChange={(e) => {
																this.selectFiles(
																	e,
																	"Attached_Files"
																);
															}}
															value=""
															multiple
														/>
													</ButtonSecondary>
												)}
										</Col>
									</Row>

									{this.props.violationId &&
										this.state.Violation_Attachments
											?.length > 0 && (
											<ViolationAttachmentsList
												filesList={
													this.state
														.Violation_Attachments
												}
												allowDelete={
													!this.state.lockFields &&
													!!this.state
														.Enable_Edit_Violation
												}
											/>
										)}
								</Col>

								<Col
									className="my-4 justify-content-start d-flex"
									xs={12}
								>
									{!this.props.violationId && (
										<ButtonPrimary
											color="primary"
											variant="contained"
											disabled={
												submitting ||
												this.props.viewMood
											}
											type="submit"
										>
											{this.props.t("save")}
										</ButtonPrimary>
									)}

									{!this.state.lockFields &&
										!!this.state.Enable_Edit_Violation &&
										this.props.violationId && (
											<ButtonPrimary
												color="primary"
												variant="contained"
												disabled={
													submitting ||
													this.props.viewMood
												}
												type="submit"
											>
												{this.props.t("save")}
											</ButtonPrimary>
										)}

									{this.state.lockFields &&
										!!this.state.Enable_Edit_Violation &&
										this.props.violationId && (
											<ButtonPrimary
												color="primary"
												variant="contained"
												disabled={
													submitting ||
													this.props.viewMood
												}
												onClick={() => {
													this.setState({
														lockFields: false,
													});
												}}
											>
												{this.props.t("edit")}
											</ButtonPrimary>
										)}

									{!this.state.lockFields &&
										!!this.state.Enable_Edit_Violation &&
										this.props.violationId && (
											<>
												<ButtonSecondary
													className="mx-2"
													color="primary"
													variant="contained"
													onClick={() => {
														this.closeViolation();
													}}
												>
													{this.props.t(
														"closeViolation"
													)}
												</ButtonSecondary>

												<ButtonDanger
													className="mx-2"
													color="primary"
													variant="contained"
													onClick={() => {
														this.cancelViolation();
													}}
												>
													{this.props.t(
														"cancelViolation"
													)}
												</ButtonDanger>
											</>
										)}
								</Col>
							</form>
						)}
					/>
				</>
			);
		}
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ViolationDetailForm));
